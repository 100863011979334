var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5 bg-start-img",style:({ backgroundImage: ("url('" + _vm.imgUrl + "')"), backgroundRepeat:'no-repeat', backgroundPosition: 'center' }),attrs:{"lg":"8"}},[_c('div',{staticClass:"w-100 h-100 d-lg-flex px-1"},[_c('logo')],1)]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg p-2 p-lg-5",attrs:{"lg":"4"}},[_c('b-overlay',{attrs:{"show":_vm.loading,"variant":"transparent","opacity":"1","blur":"5px","spinner-variant":"primary","spinner-type":"grow","spinner-small":""}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.$t('New_account.Open_new_account'))+" ")]),_c('validation-observer',{ref:"registerForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-register-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.register.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":"Persona de contacto","label-for":"register-username"}},[_c('validation-provider',{attrs:{"name":"name","vid":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-name","name":"register-name","placeholder":"Name"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Empresa","label-for":"register-company"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Empresa "),_c('code',[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Empresa","vid":"company"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-company","name":"register-company","state":errors.length > 0 ? false:null,"placeholder":"Empresa"},model:{value:(_vm.company),callback:function ($$v) {_vm.company=$$v},expression:"company"}})]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Url personalizada","label-for":"register-slug"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Url personalizada "),_c('code',[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Slug","vid":"slug","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"https://panel.evorgy.app/"}},[_c('b-form-input',{attrs:{"id":"register-slug","name":"register-slug","state":errors.length > 0 ? false:null,"placeholder":"mi-empresa"},model:{value:(_vm.slug),callback:function ($$v) {_vm.slug=$$v},expression:"slug"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Email","label-for":"register-email"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Email "),_c('code',[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Email","vid":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-email","name":"register-email","state":errors.length > 0 ? false:null,"placeholder":"john@example.com"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"register-password","label":"Password","vid":"password"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Password "),_c('code',[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Password","vid":"password","rules":"required|password|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"register-password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false:null,"name":"register-password","placeholder":"············"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"register-confirmpassword","label":"Confirm Password"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Confirmar Password "),_c('code',[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"ConfirmPassword","vid":"confirmpassword","rules":"required|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"register-confirmpassword","type":_vm.passwordFieldType,"state":errors.length > 0 ? false:null,"name":"register-confirmpassword","placeholder":"············"},model:{value:(_vm.confirmpassword),callback:function ($$v) {_vm.confirmpassword=$$v},expression:"confirmpassword"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"status","vid":"status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"id":"status","name":"status","state":errors.length > 0 ? false:null},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},[_vm._v(" "+_vm._s(_vm.$t('I do accept'))+" "),_c('b-link',[_vm._v(_vm._s(_vm.$t('Privacy_policy_terms')))])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-button',{attrs:{"variant":"primary","block":"","type":"submit","disabled":invalid}},[_vm._v(" "+_vm._s(_vm.$t('New_account.save'))+" ")])],1)]}}])}),_c('p',{staticClass:"text-center mt-2"},[_c('span',[_vm._v(_vm._s(_vm.$t('New_account.already_have_account')))]),_c('b-link',{attrs:{"to":{name:'auth-login'}}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('New_account.sign_in')))])])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }