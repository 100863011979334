<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5 bg-start-img"
        :style="{ backgroundImage: `url('${imgUrl}')`, backgroundRepeat:'no-repeat', backgroundPosition: 'center' }"
      >

        <!-- Brand logo-->
        <div class="w-100 h-100 d-lg-flex px-1">
          <logo />
        </div>
        <!-- /Brand logo-->

      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg p-2 p-lg-5"
      >
        <b-overlay
          :show="loading"
          variant="transparent"
          opacity="1"
          blur="5px"
          spinner-variant="primary"
          spinner-type="grow"
          spinner-small
        >
          <b-col
            sm="8"
            md="6"
            lg="12"
            class="px-xl-2 mx-auto"
          >
            <b-card-title class="mb-1">
              {{ $t('New_account.Open_new_account') }}
            </b-card-title>

            <!-- form -->
            <validation-observer
              ref="registerForm"
              #default="{invalid}"
            >
              <b-form
                class="auth-register-form mt-2"
                @submit.prevent="register"
              >
                <!-- username -->
                <b-form-group
                  label="Persona de contacto"
                  label-for="register-username"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="name"
                    vid="name"
                  >
                    <b-form-input
                      id="register-name"
                      v-model="name"
                      name="register-name"
                      placeholder="Name"
                    />
                  </validation-provider>
                </b-form-group>

                <!-- company -->
                <b-form-group
                  label="Empresa"
                  label-for="register-company"
                >
                  <template v-slot:label>
                    Empresa <code>*</code>
                  </template>
                  <validation-provider
                    #default="{ errors }"
                    name="Empresa"
                    vid="company"
                  >
                    <b-form-input
                      id="register-company"
                      v-model="company"
                      name="register-company"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Empresa"
                    />
                  </validation-provider>
                </b-form-group>

                <!-- slug -->
                <b-form-group
                  label="Url personalizada"
                  label-for="register-slug"
                >
                  <template v-slot:label>
                    Url personalizada <code>*</code>
                  </template>
                  <validation-provider
                    #default="{ errors }"
                    name="Slug"
                    vid="slug"
                    rules="required"
                  >
                    <b-input-group prepend="https://panel.evorgy.app/">
                      <b-form-input
                        id="register-slug"
                        v-model="slug"
                        name="register-slug"
                        :state="errors.length > 0 ? false:null"
                        placeholder="mi-empresa"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- email -->
                <b-form-group
                  label="Email"
                  label-for="register-email"
                >
                  <template v-slot:label>
                    Email <code>*</code>
                  </template>
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    vid="email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="register-email"
                      v-model="email"
                      name="register-email"
                      :state="errors.length > 0 ? false:null"
                      placeholder="john@example.com"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- password -->
                <b-form-group
                  label-for="register-password"
                  label="Password"
                  vid="password"
                >
                  <template v-slot:label>
                    Password <code>*</code>
                  </template>
                  <validation-provider
                    #default="{ errors }"
                    name="Password"
                    vid="password"
                    rules="required|password|min:8"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-form-input
                        id="register-password"
                        v-model="password"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        :state="errors.length > 0 ? false:null"
                        name="register-password"
                        placeholder="············"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          :icon="passwordToggleIcon"
                          class="cursor-pointer"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- confirm password -->
                <b-form-group
                  label-for="register-confirmpassword"
                  label="Confirm Password"
                >
                  <template v-slot:label>
                    Confirmar Password <code>*</code>
                  </template>
                  <validation-provider
                    #default="{ errors }"
                    name="ConfirmPassword"
                    vid="confirmpassword"
                    rules="required|confirmed:password"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-form-input
                        id="register-confirmpassword"
                        v-model="confirmpassword"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        :state="errors.length > 0 ? false:null"
                        name="register-confirmpassword"
                        placeholder="············"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          :icon="passwordToggleIcon"
                          class="cursor-pointer"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="status"
                    vid="status"
                    rules="required"
                  >
                    <b-form-checkbox
                      id="status"
                      v-model="status"
                      name="status"
                      :state="errors.length > 0 ? false:null"
                    >
                      {{ $t('I do accept') }}
                      <b-link>{{ $t('Privacy_policy_terms') }}</b-link>
                    </b-form-checkbox>

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-button
                  variant="primary"
                  block
                  type="submit"
                  :disabled="invalid"
                >
                  {{ $t('New_account.save') }}
                </b-button>
              </b-form>
            </validation-observer>

            <p class="text-center mt-2">
              <span>{{ $t('New_account.already_have_account') }}</span>
              <b-link :to="{name:'auth-login'}">
                <span>&nbsp;{{ $t('New_account.sign_in') }}</span>
              </b-link>
            </p>
          </b-col>
        </b-overlay>
      </b-col>
    <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Logo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BButton, BForm, BFormCheckbox, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BImg, BCardTitle, BCardText, BOverlay,
} from 'bootstrap-vue'
import axios from '@axios'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import i18n from '@/libs/i18n'

export default {
  components: {
    Logo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BOverlay,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      loading: false,
      status: '',
      name: '',
      company: '',
      password: '',
      confirmpassword: '',
      slug: '',
      sideImg: require('@/assets/images/pages/register-v2.svg'),
      // validation
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  watch: {
    slug(newData) {
      this.slug = this.sanitizeTitle(newData)
    },
    company(newData) {
      this.slug = this.sanitizeTitle(newData)
    },
  },
  methods: {
    async register() {
      this.$refs.registerForm.validate().then(async success => {
        if (success) {
          this.$recaptcha("login").then(async token => {
            this.loading = true
            await axios.get('/sanctum/csrf-cookie')
            await axios.post(`/api/v1/tenants/create`, {
              name: this.name,
              password: this.password,
              password_confirmation: this.confirmpassword,
              company: this.company,
              email: this.email,
              slug: this.slug,
              'g-recaptcha-response': token,
            })
            .then(async res => {
              this.$router.push({ name: 'auth-company' })
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: i18n.t('New_account.New account'),
                    // icon: 'CoffeeIcon',
                    variant: 'success',
                    text: i18n.t('New_account.New account created'),
                  },
                })
              })
            })
            .catch(error => {
              let errorMessage = i18n.t('errors.Check form fields')

              if (error.response.data.errors.slug) {
                errorMessage = i18n.t('errors.slug is taken')
              }

              this.loading = false
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: i18n.t('errors.Error creating new account'),
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: errorMessage,
                },
              })
              return false
            })
          })
              /*
          .catch(error => {
            this.$refs.registerForm.setErrors({ password_confirmation: error.response.data.error })
            this.loading = false
            return false
          })
          */
          //this.loading = false
        }
      })
    },
    sanitizeTitle(title) {
      return title.toLowerCase()
          .replace(/\s+/g, '-') // Replace spaces with -
          .replace(/ñ+/g, 'n') // Remove all non-word chars
          .replace(/[^\w\-]+/g, '') // Remove all non-word chars
          .replace(/\-\-+/g, '-') // Replace multiple - with single -
          .replace(/^-+/, '') // Trim - from start of text
          .replace(/-+$/, '')
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
